(function () {
  $(() => initFilterable());

  const initFilterable = () => {
    const hiddenClassName = "filterable-hidden";
    const $filterInput = $('input[name="card-filter"]');
    const $filterable = $($filterInput.data("target"));

    $filterInput.on("input change", (_e) => {
      const search = $filterInput.val() || "";
      $elements = $filterable.find("*[data-search]");

      if (search == "") {
        return $elements.removeClass(hiddenClassName);
      }

      const searchParts = search.toLowerCase().split(" ");
      $elements.each(function () {
        const $element = $(this);
        const searchValue = ($element.data("search") || "")
          .toString()
          .toLowerCase();

        if (searchValue !== "") {
          searchParts.find((term) => term !== "" && searchValue.match(term))
            ? $element.removeClass(hiddenClassName)
            : $element.addClass(hiddenClassName);
        }
      });
    });

    // on enter-press open found table link (show action)
    $filterInput.on("keyup", (e) => {
      if (e.key === "Enter") {
        // on press 'enter'
      }

      if (e.key === "Escape" || e.key === "Esc") {
        $filterInput.val("").trigger("change");
      }
    });
  };
})();
