import Sortable from "sortablejs";

(function () {
  $(function () {
    initOrderableLists();
  });


  function doSomethingElse() {
    
  }

  function initOrderableLists() {
    $("[data-orderable-list]").each(function () {
      new Sortable(this, {
        animation: 150,
        dragClass: "orderable-drag",
        onEnd: (e) => {
          $(this).trigger("orderableList:change", e, $(this), e.item);
        },
      });
    });

    function callMeFromHere() {
      debugger;
    };
  }
})();
