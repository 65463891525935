import AstraRoger from "astraRoger";

// Quick & dirty heat map implemtation
(function () {
  const EVENTS = {
    HOVER: "hover",
    SELECT: "select",
    OUT: "out",
    INITIALIZED: "initialized",
    LOADING: "loading",
    COMPLETE: "complete",
    RENDER: "render",
    RESIZE: "resize",
  };

  const IMAGE_SIZES = {
    small: 420,
    medium: 800,
    standard: 1024,
    large: 1280,
    ultra: 1920,
  };

  const colorByValue = (value) => {
    if (!value) {
      return "hsla(0, 0%, 65%, 0.75)";
    }

    const h = (1.0 - value) * 240;
    return "hsla(" + h + ", 100%, 50%, 0.75)";
  };

  let hoveredId = null;
  const width = window.innerWidth * 0.9;
  const imageSize =
    Object.keys(IMAGE_SIZES).find((key) => IMAGE_SIZES[key] - width > 0) ||
    "ultra";

  // Div element, stale if not in DOM
  const rogerElement = document.getElementById("rogerHeatmap");

  if (!rogerElement) {
    return;
  }

  const tooltipElement = rogerElement.querySelector(".heatmap-tooltip");
  const options = rogerElement.dataset;
  const masks = JSON.parse(options.masks);
  const maxValue = Math.max(...Object.values(masks).map((m) => m.value || 0));
  // Callbacks for mask coloering
  const onMaskRender = (id, status) => {
    const mask = masks[id];
    if (!mask) {
      return {
        fillStyle: "hsla(0, 0%, 65%, 0.75)",
        lineWidth: "0",
        strokeStyle: "rgba(255, 255, 255, 1)",
      };
    }
    const color = colorByValue(mask.value / maxValue);

    return {
      fillStyle: color,
      lineWidth: status == "hover" ? "4" : "0",
      strokeStyle: "rgba(255, 255, 255, 1)",
    };
  };

  const updateTooltip = () => {
    if (tooltipElement) {
      const offset = roger.getMaskBoundsById(hoveredId);
      const mask = masks[hoveredId] || {};

      if (offset) {
        // set tooltip position
        tooltipElement.style.top = offset.y + "%";
        tooltipElement.style.left = offset.x + "%";
        tooltipElement.style.display = "block";
        tooltipElement.innerHTML = [
          `<strong class="unit-name">${mask.name || hoveredId}</strong>`,
          `<small><i class="fal fa-hand-pointer mr-2"></i>${
            mask.views || 0
          } / ${mask.interactions || 0}</small>`,
          `<small><i class="fal fa-user-friends mr-2" ></i>${
            mask.viewers || 0
          }</small>`,
        ].join("<br />");
      }
    }
  };

  // Plugin initialization
  const roger = new AstraRoger({
    debug: false,
    imageSize,
    maskRenderCallback: onMaskRender,
  });

  roger.on(EVENTS.COMPLETE, () => {});

  roger.on(EVENTS.SELECT, (id) => {
    hoveredId = id;

    updateTooltip();

    const mask = masks[hoveredId];
    if (mask && mask.url) {
      window.open(mask.url, "_blank").focus();
    }
  });

  roger.on(EVENTS.HOVER, (id) => {
    hoveredId = id;

    updateTooltip();
  });

  roger.on(EVENTS.OUT, () => {
    hoveredId = null;

    updateTooltip();
  });

  roger.on(EVENTS.RENDER, (angle) => {
    if (!hoveredId) {
      tooltipElement.style.display = "none";

      return;
    }

    updateTooltip();
  });

  roger.initialize(rogerElement);
  roger.setPath(options.path);
})();
