(function () {
  var MAX_FILE_COUNT = 50;
  var allFiles = [];

  $(document).ready(function () {
    initDragDropUpload();
    // $('[data-toggle="tooltip"]').tooltip();
  });

  function initDragDropUpload() {
    $element = $(".drag-drop-upload");
    $label = $(".drag-drop-upload > label");
    $inputDrag = $('.drag-drop-upload > input[data-type="d"]');
    $inputChooser = $('.drag-drop-upload > input[data-type="c"]');
    $button = $element.find(".drag-upload");
    $counter = $element.find(".drag-counter");
    $files = $element.find(".drag-files");
    $gallery = $(".form-gallery");

    options = $element.data();

    if (dragSupport()) {
      $label.addClass("has-drag");

      $element.on("drag dragstart dragover dragenter", function () {
        $files.addClass("to-background");
      });

      $element.on("drop dragend dragleave", function () {
        $files.removeClass("to-background");
      });

      $label
        .on(
          "drag dragstart dragend dragover dragenter dragleave drop",
          function (e) {
            e.preventDefault();
            e.stopPropagation();
          }
        )
        .on("dragover dragenter", function () {
          $label.addClass("is-dragover");
          $files.addClass("to-background");
        })
        .on("dragleave dragend drop", function () {
          $label.removeClass("is-dragover");
          $files.removeClass("to-background");
        })
        .on("drop", function (e) {
          $inputDrag.prop("files", e.originalEvent.dataTransfer.files).change();
        });
    }

    $inputDrag.on("change", onChange);
    $inputChooser.on("change", onChange);
    $button.on("click", uploadImages);

    $files.on("click", ".drag-file", onDelete);

    function onChange(e) {
      var files = $(this).prop("files");
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        if (allFiles.indexOf(file) < 0 && allFiles.length < MAX_FILE_COUNT) {
          allFiles.push(file);
        }
      }

      updatePreviewArea();
      updateCounter();
    }

    function onDelete() {
      index = parseInt($(this).data("index"));
      allFiles.splice(index, 1);

      $(this).remove();
      updateCounter();
    }

    function updatePreviewArea() {
      $files.html("");
      if (allFiles.length) {
        for (var i = 0; i < allFiles.length; i++) {
          var file = allFiles[i];
          $files.append(
            '<li class="drag-file" data-index="' +
              i +
              '" style="background-image: url(' +
              URL.createObjectURL(file) +
              ')" ><div class="loader" /></li>'
          );
        }
        $label.addClass("has-files");
      } else {
        $label.removeClass("has-files");
      }
    }

    function updateCounter() {
      $counter.html([allFiles.length, MAX_FILE_COUNT].join(" / "));
      $counter.toggleClass("full", allFiles.length === MAX_FILE_COUNT);
    }

    function uploadImage(file) {
      var formData = new FormData();
      formData.append(options.name + "[file]", file);

      // Attach given params
      if (options.params) {
        for (const key in options.params) {
          formData.append(options.name + "[" + key + "]", options.params[key]);
        }
      }

      var fileIndex = parseInt(allFiles.indexOf(file));
      var $file = $('.drag-file[data-index="' + fileIndex + '"]');

      $.ajax({
        url: options.path + "/create_" + options.name,
        type: "POST",
        enctype: "multipart/form-data",
        contentType: false,
        processData: false,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            $('meta[name="csrf-token"]').attr("content")
          );
          $file.addClass("is-loading");
        },
        data: formData,
        success: function (data) {
          allFiles.splice(parseInt(allFiles.indexOf(file)), 1);

          $file.removeClass("is-loading");
          $file.children(".loader").remove();
          if ($gallery.length) {
            $file.append(
              '<a class="form-gallery-delete" data-confirm="Sind Sie sicher?" rel="nofollow" data-method="delete" href="' +
                options.path +
                "/delete_" +
                option.name +
                "?" +
                option.name +
                "id=" +
                data.id +
                '"><i class="fas fa-times"></i></a>'
            );

            $gallery.append($file.addClass("form-gallery-thumb"));
          } else {
            // $file.remove();
            $file.addClass("success");
          }

          if (allFiles.length < 1) {
            $button.attr("disabled", false);
            window.location.reload();
          }
        },
        error: function (req) {
          $file.remove();
          var message =
            "Es ist ein Fehler beim hochladen aufgetreten, bitte nur Bilder im Format .png und .jpg hochladen.";
          $(".alerts").append(
            '<div class="alert alert-danger alert-dismissible slide show><div class="container position-relative">' +
              message +
              '<button class="close" type="button" data-dismiss= "alert"><i class="fas fa-times" /></button></div></div>'
          );
        },
        complete: function () {
          $file.removeClass("is-loading");
          updateCounter();
        },
      });
    }

    function uploadImages() {
      if ($button.attr("disabled") || allFiles.length === 0) {
        return;
      }

      $button.attr("disabled", true);

      for (var i = 0; i < allFiles.length; i++) {
        uploadImage(allFiles[i]);
      }
    }
  }

  function dragSupport() {
    var div = document.createElement("div");
    return (
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  }
})();
