import Sortable from "sortablejs";

(function () {
  $(function () {
    initSelectLists();
  });

  function initSelectLists() {
    $("[data-select-list]").each(function () {
      console.log("list found and init");
      const $selectList = $(this);
      const $left = $selectList.find("[data-select-list-left]");
      const $right = $selectList.find("[data-select-list-right]");

      new Sortable($left.get(0), {
        group: $selectList.data("selectList"),
        onAdd: (e) => {
          window.setTimeout(() => {
            $(e.item).find("input").prop("checked", true);
          }, 100);
        },
        onRemove: (e) => {
          window.setTimeout(() => {
            $(e.item).find("input").prop("checked", false);
          }, 100);
        },
        onEnd: (e) => {
          $(this).trigger("selectableList:change", e, $(this), e.item);
        },
      });

      new Sortable($right.get(0), {
        group: $selectList.data("selectList"),
        sort: false,
      });
    });
  }
})();
