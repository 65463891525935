import _ from "lodash";
import { Chart, registerables } from "chart.js";

(function () {
  function renderChart(el, opts = {}) {
    if (!el) {
      return;
    }

    const ctx = el.getContext("2d");
    const data = JSON.parse(el.dataset.chart);
    const options = _.merge(
      {
        responsive: true,
        scales: {
          y: {
            min: 0,
            ticks: {
              callback: function (value, _index, _ticks) {
                if (Number.isInteger(value)) {
                  return value;
                }
              },
            },
          },
        },
      },
      opts
    );
    const chartData = { data, options };
    new Chart(ctx, chartData);
  }

  $(() => {
    const durationOptions = {
      interaction: {
        mode: "point",
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) =>
              `${context.dataset.label} (${context.label}): ${context.formattedValue}`,
          },
        },
      },
    };
    const countOptions = {
      scales: {
        x: {
          ticks: {
            callback: function (value, index) {
              return index % 2 === 0 ? this.getLabelForValue(value) : "";
            },
          },
        },
      },
    };

    Chart.register(...registerables);
    renderChart(
      document.getElementById("agent-appointments-count"),
      countOptions
    );
    renderChart(
      document.getElementById("agent-appointments-duration"),
      durationOptions
    );
    renderChart(document.getElementById("appointments-count"), countOptions);
    renderChart(
      document.getElementById("appointments-duration"),
      durationOptions
    );
    renderChart(document.getElementById("ratings"));
  });
})();
