(function () {
  $(() => {
    $(".spotlight").each((index, element) => initSpotlight($(element)));
  });

  const initSpotlight = ($spotlightElement) => {
    const url = $spotlightElement.data("spotlight");

    const $formElement = $spotlightElement.find("form");
    const $inputElement = $spotlightElement.find('input[name="search"]');
    const $resultsElement = $spotlightElement.find(".spotlight-results");
    const $clearElement = $spotlightElement.find(".spotlight-input-clear");
    const $blindoutElement = $(".spotlight-blindout");

    let currentValue = null;
    let currentXhr = null;
    const abortCurrentXhr = () => {
      if (!currentXhr) {
        return;
      }

      currentXhr.abort();
      currentXhr = null;
    };

    const openSpotlight = () => {
      $spotlightElement.addClass("spotlight-active");
      $resultsElement.show();
      $blindoutElement.show();
    };

    const closeSpotlight = () => {
      $spotlightElement.removeClass("spotlight-active");
      $resultsElement.html("").hide();
      $blindoutElement.hide();
      $clearElement.hide();
    };

    $formElement.on("ajax:beforeSend", (event) => {
      abortCurrentXhr();

      currentXhr = event.detail[0];
      $spotlightElement.addClass("spotlight-busy");
    });

    $formElement.on("ajax:complete", (event) => {
      currentXhr = null;

      openSpotlight();
      $spotlightElement.removeClass("spotlight-busy");
    });

    $inputElement.on("click", (e) => {
      $blindoutElement.show();
      $clearElement.show();
      if ($inputElement.val()) {
        $inputElement.trigger("change");
      }
    });

    $inputElement.on("input change", (e) => {
      if (currentValue === $inputElement.val()) {
        return true;
      }

      currentValue = $inputElement.val() || "";
      // Note: Guard clause doesn't work inside event handler
      if (currentValue !== "" && currentValue.length > 1) {
        Rails.fire($formElement.get(0), "submit");
        openSpotlight();
      } else {
        // TODO: instead of hiding the results panel, waiting for design recommandations (info screen?)
        $resultsElement.html("").hide();
      }
    });

    $clearElement.on("click", () => {
      abortCurrentXhr();
      $inputElement.val("").trigger("change");
      closeSpotlight();
    });

    $blindoutElement.on("click", () => {
      abortCurrentXhr();
      closeSpotlight();
    });

    $inputElement.on("keyup", (e) => {
      if (e.key === "Enter") {
        resultLinks = $resultsElement.find(".spotlight-result-link");
        if (resultLinks.length === 1) {
          resultLinks.first().get(0).click();
        }
      }

      if (e.key === "Escape" || e.key === "Esc") {
        $inputElement.val("").trigger("change");
        closeSpotlight();
      }
    });
  };
})();
