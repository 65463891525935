(function () {
  $(function () {
    initPanoramas();
  });

  function initPanoramas() {
    $(".panorama > .panorama-viewer").each((index, element) => {
      const options = $(element).data();
      const $pitch = $(".panorama-position-target[name*='pitch']");
      const $yaw = $(".panorama-position-target[name*='yaw']");

      const viewer = pannellum.viewer(element, {
        panorama: options.src,
        autoLoad: true,
        pitch: options.posPitch || 1,
        yaw: options.posYaw || 1,
        hotSpots: options.hotspots || [],
      });

      viewer.on("animatefinished", () => {
        if ($pitch.length > 0) {
          $pitch.val(viewer.getPitch().toFixed(2));
        }
        if ($yaw.length > 0) {
          $yaw.val(viewer.getYaw().toFixed(2));
        }
      });
    });
  }
})();
