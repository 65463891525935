// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
// require("channels");
require("datepicker");
require("moment");
require("bootstrapSortable");
require("trix");
require("@rails/actiontext");
require("dragDropUploader");
require("orderableList");
require("filterable");
require("bootstrap-colorpicker");
require("bootstrap-select");
require("bootstrap-select/dist/js/i18n/defaults-de_DE");
require("chart.js");
require("stats");
require("selectableList");
require("pannellum");
require("panorama");
require("spotlight");
require("heatmap");

// Import from bootstrap gem
import "bootstrap";

// Set reference for legacy ujs calls
window.$ = $;
window.moment = moment;

const MODALS = ["remote", "calendar", "sidebar"];

$(() => {
  initialize();
});

// Re-initialize 3rd-party plugins on modal content change
$(MODALS.map((m) => `#${m}ModalContent`).join(", ")).on("changed", (e) => {
  $(".selectpicker").selectpicker();
  $('[data-toggle="tooltip"]').tooltip();

  initializeDatePicker();
  initializeColorPicker();
  initializeCustomRadio();
  initializeBootstrap();
  initRemoteModals();
});

const initRemoteModals = () => {
  const modalIds = MODALS.map((m) => `${m}Modal`);

  // Auto show remote modal when ajax complete
  $("[data-remote='true']").on("ajax:complete", function () {
    const modalId = modalIds.find((id) =>
      (this.getAttribute("href") || "").match(id)
    );
    if (modalId) {
      $(".modal").modal("hide");
      $(`#${modalId}`).modal("show");
    }
  });
};

const initialize = () => {
  // Initialize date and color picker plugins
  initializeDatePicker();
  initializeColorPicker();
  initializeCustomRadio();
  initializeBootstrap();
  initRemoteModals();

  $(".asset_collection").on("orderableList:change", (e) => {
    const elements = e.currentTarget.querySelectorAll("[data-orderable]");
    const url = e.currentTarget.dataset.url;
    const assets = [];
    for (let i = 0; i < elements.length; i++) {
      assets.push(parseInt(elements[i].dataset.id));
    }

    $.ajax({
      url,
      type: "PUT",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      data: { assets },
      error: (e) => {
        window.alert("Error updating ordered assets");
      },
    });
  });

  $("#new_asset").on("ajax:beforeSend", (e) => {
    $(e.currentTarget).find("input").attr("disabled", true);
  });

  $("#new_asset").on("ajax:success", (e) => {
    $(e.currentTarget).find("input").removeAttr("disabled");
    window.setTimeout(() => {
      window.location.reload();
      window.location.replace(
        window.location.pathname + window.location.search + window.location.hash
      );
    });
  });

  $("#new_asset").on("ajax:error", (e) => {
    window.alert(e);
    $(e.currentTarget).find("input").removeAttr("disabled");
  });

  if (window.history.length > 0) {
    $(".back-button").on("click", (e) => {
      if (window.history.length < 1) {
        return;
      }

      e.preventDefault();
      window.history.back();
    });
  } else {
    $(".back-button").remove();
  }
};

const initializeBootstrap = () => {
  // Initialize tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Auto show modals
  $(".modal.show").modal();

  // Auto dismiss alerts
  $(".alert-auto-dismiss")
    .delay(4000)
    .slideUp(150, function () {
      $(this).alert("close");
    });

  // Initialize custom file input
  $("input.custom-file-input").change((e) => {
    const names = Array.from(e.target.files).map((f) => f.name);
    $(e.target).siblings(".custom-file-label").html(names.join(", "));
  });

  // This fixes the issue with the active class not being
  // properly removed.
  // See https://github.com/twbs/bootstrap/issues/19374 for
  // further information
  $('a[data-toggle="tab"]').on("click", function (event) {
    event.preventDefault();
    $(this)
      .closest("[role='tablist']")
      .find("a[data-toggle='tab']")
      .not($(this))
      .removeClass("active");
    $(this).tab("show");
  });
};

const initializeCustomRadio = () => {
  $(".custom-radio").on("change", (e) => {
    $(e.target)
      .parents(".custom-radio-option")
      .toggleClass("active")
      .siblings()
      .removeClass("active");
  });
};

const initializeDatePicker = () => {
  // Initialize DatePicker
  $(".date-picker").each((index, element) => {
    const $datePicker = $(element);
    const $input = $datePicker.find("input");

    $datePicker.datetimepicker({
      minDate: $input.val() === "" && moment(),
      stepping: 5,
      format:
        $datePicker.data("format") === "date"
          ? "DD.MM.YYYY"
          : $datePicker.data("format") === "time"
          ? "HH:mm"
          : "DD.MM.YYYY, HH:mm",
      extraFormats: ["YYYY-MM-DD HH:mm:ss UTC", "DD.MM.YYYY, HH:mm"],
      defaultDate: $input.val(),
      allowInputToggle: true,
      inline: $datePicker.data("inline") !== undefined,
      keepOpen: $datePicker.data("inline") !== undefined,
      sideBySide: $datePicker.data("inline") !== undefined,
      // defaultDate: moment().add(1, 'd').hours(12).minutes(0),
      icons: {
        time: "far fa-clock",
        date: "far fa-calendar",
        up: "fal fa-long-arrow-up",
        down: "fal fa-long-arrow-down",
        previous: "fal fa-long-arrow-left",
        next: "fal fa-long-arrow-right",
        today: "far fa-crosshairs",
        clear: "far fa-trash-alt",
        close: "fas fa-times",
      },
    });

    if ($datePicker.data("depends")) {
      const $dependingPicker = $($datePicker.data("depends"));

      $dependingPicker.on("dp.change", (e) => {
        if (e.date) {
          $datePicker.datetimepicker(e.date.add(15, "m"));
        }
        $datePicker.datetimepicker("minDate", e.date);
      });
    }
  });
};

const initializeColorPicker = () => {
  // Initialize color picker
  $(".color-picker").colorpicker({
    useAlpha: true,
    format: "alpha",
    horizontal: true,
  });
};
